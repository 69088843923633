import React from "react";
import point from "./../../../../Images/point.png";

const HairBotoxInSahakarNagarMaintain = () => {
  return (
    <div className="hairSalonInSahakaraWhy">
      <h5>How to Maintain Hair Botox Results?</h5>
      <p>
        To extend the longevity of your Hair Botox treatment, follow these tips:
      </p>
      <ul>
        <li>
          <span>
            <img src={point} alt="point" /> Use a sulfate-free shampoo and
            conditioner to prevent stripping away the treatment.
          </span>
        </li>
        <li>
          <span>
            <img src={point} alt="point" /> Limit heat styling and always
            use a heat protectant before using styling tools.{" "}
          </span>
        </li>
        <li>
          <span>
            <img src={point} alt="point" /> Avoid excessive washing—washing
            your hair 2-3 times a week is ideal.{" "}
          </span>
        </li>
        <li>
          <span>
            <img src={point} alt="point" /> Use a silk pillowcase to reduce
            friction and prevent frizz while sleeping.{" "}
          </span>
        </li>

        <li>
          <span>
            <img src={point} alt="point" />
            Hydrate and nourish your hair with regular deep conditioning
            treatments.{" "}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default HairBotoxInSahakarNagarMaintain;
