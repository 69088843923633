import React from 'react'
import doScalpMassagesBanner from "./../../../../../Images/Blog/Do_Scalp_Massage_Promote/Banner.png";

const DoScalpMassagesImg = () => {
  return (
    <div className="hairTrimImg">
    <img src={doScalpMassagesBanner} alt="doScalpMassagesBanner" loading="lazy"/>
  </div>
  )
}

export default DoScalpMassagesImg
