import React from "react";
import checked from "./../../../../Images/checked.png";

const HairBotoxInSahakarNagarWhy = () => {
  return (
    <div className="hairSalonInSahakaraWhy">
      <h5>
        Why SCENT is the Best Choice for Hair Botox Treatment in Sahakara Nagar?
      </h5>
      <p>
        At SCENT, we pride ourselves on offering the best Hair Botox Treatment
        in Sahakara Nagar with the following benefits:{" "}
      </p>
      <ul>
        <li>
          <span>
            <img src={checked} alt="checked" /> Experienced hairstylists with
            expertise in hair restoration treatments.
          </span>
        </li>
        <li>
          <span>
            <img src={checked} alt="checked" /> Personalized consultation and hair
            care plans tailored to your unique needs.
          </span>
        </li>
        <li>
          <span>
            <img src={checked} alt="checked" /> Premium-quality, chemical-free
            products that are safe for all hair types.
          </span>
        </li>
        <li>
          <span>
            <img src={checked} alt="checked" /> Affordable pricing with exceptional
            results that make it worth every penny.
          </span>
        </li>

        <li>
          <span>
            <img src={checked} alt="checked" /> Luxurious and comfortable salon
            experience with state-of-the-art facilities.
          </span>
        </li>
      </ul>
    </div>
  );
};

export default HairBotoxInSahakarNagarWhy;
