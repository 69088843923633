import React from 'react'
import dot from "./../../../../Images/dot.png";

const HairBotoxInJakkurWho = () => {
  return (
    <div className="hairSalonInSahakaraWhy">
    <h5>Who Can Benefit from Hair Botox Treatment?</h5>
    <p>Hair Botox is an excellent choice for individuals experiencing:</p>
    <ul>
      <li>
        <span>
          <img src={dot} alt="dot" /> Dry, brittle, or damaged hair due to
          excessive heat styling or chemical treatments.
        </span>
      </li>
      <li>
        <span>
          <img src={dot} alt="dot" /> Frizzy and unmanageable hair that needs
          a long-term smoothing solution.{" "}
        </span>
      </li>
      <li>
        <span>
          <img src={dot} alt="dot" /> Thin, lifeless hair that lacks volume
          and shine.{" "}
        </span>
      </li>
      <li>
        <span>
          <img src={dot} alt="dot" /> Color-treated or bleached hair that
          needs intense hydration and repair.{" "}
        </span>
      </li>
    </ul>
  </div>
  )
}

export default HairBotoxInJakkurWho
